import { render, staticRenderFns } from "./Keystone_v2.vue?vue&type=template&id=354a87a4&scoped=true"
import script from "./Keystone_v2.vue?vue&type=script&lang=js"
export * from "./Keystone_v2.vue?vue&type=script&lang=js"
import style0 from "./Keystone_v2.vue?vue&type=style&index=0&id=354a87a4&prod&scoped=true&lang=css"
import style1 from "./Keystone_v2.vue?vue&type=style&index=1&id=354a87a4&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "354a87a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBarNavIcon,VBtn,VChip,VSnackbar,VSpacer,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider,VToolbar,VToolbarTitle})
